import React from 'react';
import './Contacts.css';

const Contacts = () => {
  return (
<>
            <h2>Feel free to reach out</h2>
            <h3>Contacts</h3>
            <div className="contacts-container">
                <div className="contact">
                    <div className='contact-label'>
                        <img src="dima.jpg" alt="Image 1" />
                        <div className='desc'>Dima<br/>full stack dev, sw architect</div>
                    </div>
                    <div className="overlay-contacts">
                        <div className="overlay-contacts-text">
                            <a href='https://www.linkedin.com/in/noskodmi/'><img src="linkedin.png"/></a>
                            <a href='https://twitter.com/noskodmi'><img src="twitter.png"/></a>
                            <a href='https://github.com/noskodmi'><img src="github.png"/></a>
                        </div>
                    </div>
                </div>
                <div className="contact">
                    <div className='contact-label'>
                        <img src="george.jpg" alt="Image 2" />
                        <div className='desc'>George<br/>3d artist, sw architect</div>
                    </div>
                    <div className="overlay-contacts">
                        <div className="overlay-contacts-text">
                            <a href='https://www.linkedin.com/in/george-muratov/'><img src="linkedin.png"/></a>
                            <a href='https://twitter.com/cryptoham42'><img src="twitter.png"/></a>
                            <a href='https://github.com/sedoy26'><img src="github.png"/></a>
                        </div>
                    </div>
                </div>
                <div className="contact">
                    <div className='contact-label'>
                        <img src="julia.jpg" alt="Image 3" />
                        <div className='desc'>Yulia<br/>full stack dev, designer</div>
                    </div>
                    <div className="overlay-contacts">
                        <div className="overlay-contacts-text">
                            <a href='https://www.linkedin.com/in/yulia-sultanbekova/'><img src="linkedin.png"/></a>
                            <a href='https://twitter.com/jemjasott'><img src="twitter.png"/></a>
                            <a href='https://github.com/sultanby'><img src="github.png"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
  );
};

export default Contacts;
