import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Showcases from './Showcases';
import Contacts from './Contacts';
import Header from './Header';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={ <Showcases /> } />
          <Route path="/contacts" element={ < Contacts /> } />
        </Routes>
        <footer className="footer">
          &copy; 2022-2024 Crolux. All rights reserved.
        </footer>
      </div>
    </Router>
  );
}

export default App;
