import React from 'react';
import './Showcases.css';

const Showcases = () => {
    return (
        <>
            <div id='opening'>
                <div className="content">
                    <h1>Unleash the future of digital ownership with Crolux.</h1>
                    <h2>We're engineering tomorrow's entertainment landscape</h2>
                </div>
            </div>

            <h1>Showcase</h1>
            <p>Explore the web3 solutions we’ve delivered for our clients.</p>
            <div className="showcases-container">
                <div className="showcase">
                    <div className='img-label'>
                        <img src="croids.jpg" alt="Image 1" />
                        <div className='desc'>NFT mint</div>
                    </div>
                    <div className="overlay">
                        <div className="overlay-text">Croids NFT project for undisclosed customer</div>
                    </div>
                </div>
                <div className="showcase">
                    <div className='img-label'>
                        <img src="NFTMarket2.png" alt="Image 2" />
                        <div className='desc'>NFT market</div>
                    </div>
                    <div className="overlay">
                        <div className="overlay-text">NFT Market for Croids for undisclosed customer</div>
                    </div>
                </div>
                <div className="showcase">
                    <div className='img-label'>
                        <img src="tonrunner.jpg" alt="Image 3" />
                        <div className='desc'>Ton Runner</div>
                    </div>
                    <div className="overlay">
                        <div className="overlay-text">TON Runner is a game avaliable on Telegram.
                        <form action="https://t.me/ton_runnerbot">
                            <button className='button-4' type="submit">play now</button>
                        </form>
                        </div>
                    </div>
                </div>
            </div>

            <h1>Expertise</h1>
            <p>Our team possess a broad range of skills, experience and specialisms covering all areas of web3.</p>

            <div id='expertise'>

                <div className='expertise-container'>
                    <img src='https://cdn.aglty.io/fortanix/web3/ignite-one-time-signer/top%20image.svg'></img>

                    <div>
                        <h3>Empowering Trust and Security</h3>
                        <p>Our expertise in blockchain-based digital identity solutions enables individuals and organizations to establish secure and immutable digital identities. Leveraging blockchain technology, we provide solutions for identity verification, authentication, and privacy protection, fostering trust and security in digital interactions.</p>
                    </div>
                </div>



                <div className='expertise-container odd'>
                    <img src='https://cdn.aglty.io/fortanix/web3/fortanix-launchnodes-solution-for-cosmos-and-tendermint-blockchains/1.webp'></img>
                    <div>
                        <h3>Engineering Tomorrow's Entertainment Landscape</h3>
                        <p>Crafting tailored solutions for the entertainment industry, we enable companies to embrace blockchain technology's transformative potential. From revolutionizing ticketing systems to decentralizing content distribution, our solutions drive efficiency, transparency, and innovation in entertainment ecosystems.</p>
                    </div>
                </div>

                <div className='expertise-container'>
                    <img src='https://cdn.aglty.io/fortanix/web3/non-custodial-warm-wallet/fortanix-custodial-warm-wallet.svg'></img>

                    <div>
                        <h3>Redefining Financial Freedom</h3>
                        <p>Specializing in the development of decentralized finance (DeFi) applications, we empower users to access financial services without intermediaries. Our solutions facilitate peer-to-peer lending, yield farming, decentralized exchanges, and other DeFi innovations, democratizing access to financial tools and redefining financial freedom.</p>
                    </div>
                </div>

                <div className='expertise-container odd'>
                    <img src='https://cdn.aglty.io/fortanix/web3/signing-provider-for-tmkms/fortanix-signing-provider-for-tmkms.svg'></img>
                    <div>
                        <h3>Revolutionizing Transparency and Efficiency</h3>
                        <p>Our proficiency in blockchain-powered supply chain solutions enables organizations to achieve unprecedented transparency, traceability, and efficiency in their supply chain operations. By leveraging blockchain technology, we provide solutions for real-time tracking, authentication, and optimization of supply chain processes, driving innovation and sustainability across industries.</p>
                    </div>
                </div>

            </div>


        </>
    );
};

export default Showcases;
