import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Import the CSS file for styling

function Header() {
    return (
        <header className="header">
            <Link to="/" className="logo">
                CROLUX
            </Link>
            <div className="right">
                <Link to="/" className="contact-link">
                    showcases
                </Link>
                <Link to="/contacts" className="contact-link">
                    contact
                </Link>
            </div>
        </header>
    );
}

export default Header;
